// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "1.0.0",
  apiPhP: 'https://crm.thomas-talk.me/api/staff/?',
  serverAverias: "https://apiolt.fibextelecom.info", //"http://localhost:9053", // "https://apiolt.fibextelecom.info", // "https://apiolt.thomas-talk.me",
  ApiThomas: 'https://api.thomas-talk.me/',
  ApiBD:'https://backend.thomas-talk.me/api/v1/',
  urlGraphql: 'U2FsdGVkX18cntqrm+tyUHcSxO2g4QBhIprJbYdS7LlBcjvxQgUqevgpJk8Ac5UT',
  urlDBFULL: 'U2FsdGVkX1//umtzy2z39NfEtKhKNQgH4icHTP6+ajb+3XsVUX0g6llWA/6fnbVdVckdwQI5H2q8iV/CIoI8IA==',
  urlApisslThomas: "U2FsdGVkX1/8RmO6yYcayLk6LINfj70Pec3+FY4c/npI1lJEpSy/1atd3xr4nL69IQTs3oREOuh8QaIy8HxrNA==",
  urlApiTLS: "U2FsdGVkX1/Axy7GvMSn4Rs8j4ZFk85P57+4ZiVdNuVaa77C7KRWW4TZ0cNj6xkX",//"U2FsdGVkX18pq04gEPDRxMB43nS/1oPqJRBKX30L+Gsu6rq25aZzsGe1YN21jyVS"
  urlConsultassslThomas: 'U2FsdGVkX1+Unz46wZtW4rlzR3p0bWBnHAlnTldPg/Mj4jk0qRvfcOZ3nMSIz10hspDysoHk/Opme7MsXEgMuQ==',
  urlApisslThomasSend: 'U2FsdGVkX1+kLzx81uGo7Kl1KCiK2qw+xdnXXyAindiU5C9xD1MYbTB89Ju8RMe23e4d/8l2waNnCi9XM3DCfQ==',
  decryptKey: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  decryptEnv: 'EnvironmentAaanfaion0501faoi==',
  decryptLS: 'LocalStorageAaanfaion0501faoi==',
  urlUploadService: 'U2FsdGVkX1/K6YCkLlZzcTJRHvvSd+Y+wMoq2dgjnEUSNGmx3xZg5imBdA8ojvLt',
  urlDBMaps: "https://dbmaps.thomas-talk.me/api/v1/",
  NameBD: 'thomas_cobertura',
  securityEncrt: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  ApiKeyApissl: '4p1_[51t3m4s]_f186@th0m4s',
  token: 's1e1/c1r2e-2t.2a3r*3i4a',
  tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
  authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),
  lic: '584120202020@c.us',
  upload_preset: "itenw8im",
  //ApiKey: "445792464983284",
  //CloudName:"soluciones-sms",
  ApiKey: "167871278349249",
  ApiSecret: "7I5iUVfNCJlWI8zS6E1vUSXM4oI",
  CloudName: "thomasv9",
  CaptchaSiteKey: 'U2FsdGVkX18OW0duGZvVoaj6Zsy0F9y8s7nkzFE4zIJFw1RHSMNdSP4bWYTi3Sw8Y1todd27YzB2XgAKSMmKpg==',
  urlStatus: "https://api.thomas-talk.me/olt2/get_onu_status",
  urlInfo: "https://api.thomas-talk.me/olt2/get_onu_details",
  urlAdministrative: "https://api.thomas-talk.me/olt2/get_onu_administrative_status",
  urlApiUptime: "https://apitest3.thomas-talk.me/api/",
  ApiTransf:"https://checkdigital.thomas-talk.me/",
  // urlApiUptime: "http://localhost:8000/api/",,
  authorizationCheckDigital:
  "Basic " + btoa("MIKE:GH/98H=5WSC*GWSK{}FA="),
  TokenCheckDigital:"ahskGIVS55jZPtHjrlJUbilBcz387hBfeyEkpkJVnKT-M-dK3jmPJoaLaPlcOIQakVsvS8RGlB43qigm4zM98Lrt-7Q7bRNVSxVxWy1tmADdFGArppoLLazNsEduiznB_pRSSU735f2ysI-rFfCRZJm9AujB5PGrH-EPgQulYM4VaqJaB4yy",
  authorization:
    "Basic " + btoa("Tommy:esoesuntoken"),
  tokenApiUptime:
    "laTgvGIk_iOA2E6IodgvrTGT0B2GS31rXLFQ2Hf2MEnJA_4J2L52fL4dHVMmQDwZkLGJSw8WIZMj8F1pKVDHW61qpiGhG7UHsTdbqHaQYkpVqYRHl1Pt_5F5Ymk6hMB7XNPfPrPHqye4u7EHbL0QAdzF",
  // urlThomasApi: 'https://api.thomas-talk.me/',
  // urlBackendThomas: 'https://backend.thomas-talk.me/api/v1/',
  // urlGraphql: 'https://pha.thomas-talk.me/',
  // urlDBFULL: 'http://dbfull.thomas-talk.me/api',
  // urlApisslThomas: 'https://apissl.thomas-talk.me/SAE',
  // urlUploadService: 'https://img.thomas-talk.me/api/',
  // CaptchaSiteKey: '10000000-ffff-ffff-ffff-000000000001'

  //cludinary
  upload_image_url: 'https://images-services.thomas-talk.me',
  upload_image_endpoint: 'upload-images',
  folder: "Fallas",
  upload_image_user: 'Randal',
  upload_image_password: '$%rgY6rh==vbhp93*',
  upload_image_token: 'bT5n-xuJ4JXcxIGvVsF2ZmffHg3I_h2OLcon6UvZ9kBxNAzlDIoQFmFpOWkU5yU4Z0R0GbTjK-5bXpv7PAtw6h2BlYJ2DddJKi9A5GouXgCpoxfNTbOMVwmiNAvCvDFJsULA3q-rqgESUgR9Rk4g61ttRV_1HCv-vl5-tj3lODHTtLK2_hGh',
  //cludinary/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
